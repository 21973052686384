//to ignore cli warning about deprecated mixin
$enable-deprecation-messages: false;
$ignore-warning             : true;

// stylelint-disable-next-line scss/dollar-variable-default
$primary   : #5E37FF;
$secondary : #673BB7 !default;
$success   : #10d876 !default;
$info      : #50E3C2 !default;
$warning   : #FE9431 !default;
$danger    : #FF788E !default;
$light     : #f8f9fa !default;
$dark      : #1F2641;

// Body
$body-bg                : #F6F8FE;
$body-color             : #8691b4;
// Typography
$font-family-base       : 'Poppins', sans-serif;
$headings-font-weight   : 500;
// $headings-font-family:        $fm;
$font-size-base         : 1rem !default;
$font-weight-base       : 400 !default;
// $line-height-base    : 1.6;
$h1-font-size           : 2.25rem;
$h2-font-size           : 1.875rem;
$h3-font-size           : 1.5rem;
$h4-font-size           : 1.125rem;
$h5-font-size           : 1rem;
$h6-font-size           : 0.875rem;
//
// Color system
//
$white                  : #fff !default;
$black                  : #000 !default;
$blue                   : #5e72e4;
$indigo                 : #6610f2 !default;
$purple                 : #6f42c1 !default;
$pink                   : #e83e8c !default;
$red                    : #EE3232;
$orange                 : #fd7e14 !default;
$yellow                 : #FFFA6F;
$green                  : #297F00;
$teal                   : #20c997 !default;
$cyan                   : #3065D0;
$grays                  : () !default;
// stylelint-disable-next-line scss/dollar-variable-default

// $muted  : #666666;
$text-muted: #AEAED5;
$mine-shaft: #262626;
$ebony-clay: #232833;
$colors    : () !default;

$theme-colors        : () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors        : map-merge(("primary": $primary, "secondary": $secondary, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light, "dark": $dark), $theme-colors);
// $base-font-size   : 0.8125rem !default;
$heading-primary-size: 1.8rem;
$sub-heading-size    : 1.6rem !default;
$grid-gutter-width   : 30px;
$dropdown-lik-color  : $body-color;
$border-color        : rgba(55, 55, 89, 0.1);
$headings-color      :#1F2641 !default;
$grid-breakpoints    : (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1440);
$container-max-widths: (sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1199px) !default;




//color for coller pallate
$color_background_1: #fff;
$color_background_2: #292D3E;
$color_background_3: #2D2B55;
$color_background_4: #30243D;
$color_background_5: #193549;


//color for coller pallate
$color_primary_1: #7B6FFF;
$color_primary_2: #FF0096;
$color_primary_3: #002EFF;
$color_primary_4: #FF2F00;
$color_primary_5: #00CCD3;