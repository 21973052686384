.btn {
  border-radius: 15px;
}

.content-body {
  margin-top: 100px;
}

.transaction-widget li {
  margin-bottom: 20px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 767px) {
  div.sidebar {
    max-height: 100%;
    overflow: auto;
  }

  div.sidebar-footer {
    position: relative;
  }
}



.gradient-background {
  background: linear-gradient(to right, #75E6DA , #1BA39C);
}


/*
.gradient-background {
  background: linear-gradient(to right, #f2f2f2, #d9d9d9);

}
*/

@media (min-width: 992px) {
  .sold-contabil {
    height: 100%;
  }
}


.bullet-list {
  display: inline-block;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #999999;
}

