.settings_menu {
    ul {
        li {
            a {
                padding: 12px 0;
            }
            i {
                font-weight: bold;
                margin-right: 10px;
                font-size: 20px;
            }
            span {
                font-weight: 500;
            }
        }
    }
}

.phone_verify {
    margin-top: 50px;
    border-top: 1px solid $border-color;
    padding-top: 30px;
}

.phone_verified {
    display: flex;
    align-items: center;
    h5 {
        margin-bottom: 0;
        margin-right: 30px;
        color: $body-color;
        span {
            // background: $primary;
            color: $primary;
            padding: 9px 10px;
            border-radius: 50px;
            margin-right: 7px;
            display: inline-block;
            font-size: 18px;
            font-weight: bold;
            border: 1px solid $border-color;
            height: 40px;
            width: 40px;
            display: inline-block;
        }
    }
    .verified,
    .not-verify {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        span {
            background: $success;
            color: $white;
            padding: 10px;
            border-radius: 50px;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            margin-right: 15px;
            font-weight: bold;
            font-size: 12px;
        }
    }
    .not-verify {
        span {
            background: $danger;
        }
    }
}

// Security
.id_card {
    padding: 20px;
    border: 1px solid $border-color;
    border-radius: 5px;
}