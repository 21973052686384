.table {

    th,
    td {
        padding    : 18px;
        // color: $headings-color;
        font-weight: 600;
    }
}

.table-responsive-sm {
    min-width: 48rem;
}

.table {
    border-collapse: separate;
    border-spacing : 0 1rem;

    tr {

        td,
        th {
            background    : $body-bg;
            border        : 0px;
            vertical-align: middle;

            &:first-child {
                border-top-left-radius   : 5px;
                border-bottom-left-radius: 5px;
            }

            &:last-child {
                border-top-right-radius   : 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}

// .table-striped {
//     tbody tr:nth-of-type(#{$table-striped-order}) {
//         background-color: lighten($primary, 45%);
//     }
// }