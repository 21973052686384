button,
.btn {
    padding      : 5px 30px;
    display      : inline-block;
    border-radius: 50px;
    min-width    : 130px;
    font-size    : 16px;
    font-weight  : 500;
    padding      : 12px;
    // color        : #fff;
}