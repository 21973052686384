
















// .authincation{
//     height: 100%;
// }
.authincation {
    // height: 100vh;
}

.auth-form {
    // padding: 30px;
    // background: $white;
    // box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    // border-radius: 5px;
    // .btn {
    //     height: 50px;
    //     font-weight: 700;
    // }
    border: 1px solid $border-color;
    .page-back {
        display: inline-block;
        margin-bottom: 15px;
    }
}

.verification {
    // margin-top: 100px;
    // height: 100vh;
}

.identity-content {
    text-align: center;
    .icon {
        display: flex;
        width: 75px;
        height: 75px;
        border-radius: 100px;
        margin: 0 auto;
        background: $success;
        color: darken($success, 20%);
        font-size: 30px;
        padding: 10px;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    h4 {
        max-width: 350px;
        margin: 0 auto 15px;
    }
    p {
        max-width: 350px;
        margin: 0 auto 30px;
    }
}