.sidebar {
    background: transparent;
    position  : fixed;
    left      : 0;
    height    : 100%;
    width     : 240px;
    top       : 0;
    z-index   : 3;
    padding   : 30px;

    @include respond('phone-land') {
        top       : auto;
        bottom    : 0;
        width     : 100%;
        height    : 50px;
        background: $primary;
        padding   : 0px;
    }

    .brand-logo {
        padding       : 15px 0px;
        display       : flex;
        flex-direction: column;

        @include respond('phone-land') {
            display: none;
        }

        img {
            width        : 30px;
            margin-bottom: 10px;
        }

        span {
            font-weight: 700;
            font-size  : 18px;
            color      : $headings-color;
        }
    }
}

.menu {
    margin-top : 30px;
    padding-top: 50px;
    border-top : 1px solid $border-color;

    @include respond('phone-land') {
        margin-top: 0px;
        padding   : 0px;
        border    : 0px;
    }

    ul {
        @include respond('phone-land') {
            display        : flex;
            justify-content: space-around;
            align-items    : center;
        }

        li {
            // text-align: center;

            a {
                padding      : 12px 0px;
                display      : flex;
                align-items  : center;
                margin-bottom: 5px;
                border-radius: 0px;
                font-weight  : 500;

                @include respond('phone-land') {
                    color  : $white;
                    opacity: 0.75;
                }

                &:hover,
                &:focus,
                &:active {
                    // background: $primary;
                    color: $primary;

                    @include respond('phone-land') {
                        color  : $white;
                        opacity: 1;
                    }

                }

                &.active {
                    // background: $primary;

                    i {
                        color: $primary;

                        @include respond('phone-land') {
                            color  : $white;
                            opacity: 1;
                        }
                    }

                    .nav-text {
                        // margin-left: 5px;
                        color: $primary;

                        @include respond('phone-land') {
                            color  : $white;
                            opacity: 1;
                        }
                    }
                }

                i {
                    // color    : #fff;
                    font-size: 24px;
                }

                .nav-text {
                    margin-left: 10px;

                    // color      : #fff;
                    @include respond('phone') {
                        display: none;
                    }
                }
            }
        }
    }
}

.sidebar-footer {
    position: absolute;
    bottom  : 45px;

    @include respond('phone-land') {
        display: none;
    }


    
}

.social {

        a {
            padding            : 7px 0px;
            border-radius      : 100%;
            border             : 0px solid #e2e2f4;
            margin-right       : 15px;
            // height          : 42px;
            // width           : 42px;
            border-radius      : 5px;
            display            : inline-block;
            // color        : $white;
            text-align         : center;
            font-size          : 18px;
        }
    }



    .copy_right {
        font-size: 14px;
    }